<template>
  <footer class="footer pb-0 pb-md-5 pb-lg-5 pt-5 pt-md-0 pt-lg-0" :class="[this.IsChangefooter,this.IspageName]">
    <div class="container hk-container-padding pt-0 pt-md-4 pt-lg-4">
      <div class="row pt-0 pt-md-5 pt-lg-5">
        <div class="col-12 col-md-5 col-lg-5 order-md-0 order-lg-0 order-1  pb-3 pb-md-0 pb-lg-0">
          <div class="testimonial-box-innner">
            <div class="d-flex hk-align-items-center ml-1  justify-content-lg-start justify-content-md-start">
              <div class="mb-0">
                <router-link :to="'/'"><img src="@/assets/logo/hk-logo-black.svg"  loading="lazy" style="height:30px;" alt="hk-logo-black"></router-link> </div>
              <span class="text-black footerv-line">|</span>
              <p class="text-ssm">{{ $t('home_page.hk_slogan') }}</p>
            </div>
          </div>
          <div class="mt-2">
            <span class="fs-14 footer-pharagraph">{{ $t('footer.footer_mini_text') }}</span>
          </div>
          <div>
            <span class="fs-14 footer-pharagraph">
              {{ $t('footer.footer_large_text') }}
            </span>
          </div>
          <div class="pb-0 pb-md-0 pb-lg-0">
            <img src="@/assets/logo/hk_ssl_black.png"  alt="ssl" loading="lazy" class="w-50 pt-4 pt-md-4 pt-lg-4">
          </div>
          <!-- <hr class="d-block d-sm-block d-md-none d-lg-none d-xl-none"> -->
        </div>
        <div class="col mob-text-center pt-0 pt-md-0 pt-lg-0 d-none d-md-block d-lg-block"></div>
        <div class="col mob-text-center pt-0 pt-md-0 pt-lg-0">
          <h1 class="text-black fs-14"> {{ $t('nav_bar.about_us') }}</h1>
          <ul class="list-unstyled">
            <li>
              <router-link class="nav-link" :to="'/company'">{{ $t('nav_bar.company') }}</router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="'/services'">{{ $t('nav_bar.services') }}</router-link>
            </li>
            
            <li>
              <router-link class="nav-link" :to="'/news-blogs/news'">{{ $t('nav_bar.news_blogs') }}</router-link>
            </li>
            <!-- <li>
              <router-link class="nav-link" :to="'/blogs'">Blogs</router-link>
            </li> -->
            <li>
              <router-link class="nav-link" :to="'/careers'">{{ $t('nav_bar.careers') }}</router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="'/contact-team'">{{ $t('nav_bar.contact_us') }}</router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="'/support'">{{ $t('nav_bar.support') }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col mob-text-center pt-0 pt-md-0 pt-lg-0">
          <h1 class="text-black  fs-14"> {{ $t('nav_bar.clients') }}</h1>
          <ul class="list-unstyled">
            <li>
              <router-link class="nav-link" :to="'/testimonials'">
                {{ $t('nav_bar.testimonials') }}</router-link>
            </li>
          </ul>
        </div>
        <!-- <div class="col mob-text-center pt-0 pt-md-0 pt-lg-0">
          <h1 class="text-black  fs-14">Services </h1>
          <ul class="list-unstyled">
            <li>
              <router-link class="nav-link" :to="'/services'">Support</router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="'/services'">Implementation</router-link>
            </li>
            <li>
              <router-link class="nav-link" :to="'/services'">Training</router-link>
            </li>
            <li><router-link class="nav-link" :to="'/services'">Payment Processing</router-link>
            </li>
            <li><router-link class="nav-link" :to="'/services'">Interfaces</router-link></li>
          </ul>
        </div> -->


      </div>
      
    </div>

    

  </footer>
</template>

<script>
// import $ from 'jquery';
// const { Toast } = bootstrap
import axios from 'axios'

export default {
  name: 'FooterBar',
  data() {
        return {
          IsChangefooter:'',
        }
    },
    computed: {
    //   IsChangefooter() {
    // return this.$i18n.locale;
// },
IspageName() {
    return this.$route.name ;
}
  },
     mounted(){
      
     },
    methods: {
      getUserLocation() {
      const apiUrl = 'https://ipapi.co/json/';
      axios.get(apiUrl)
        .then(response => {
          this.IsChangefooter = response.data.country_code;
        })
        .catch(error => {
          console.log('Error:', error);
        });
    }
    },
    watch: {
    $route(to, from) {
  
      this.getUserLocation();
    }
  }
  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/assets/sass/config/variables.scss';
@import '@/assets/sass/main.scss';

footer {
  background: $white;
  // margin-top: -12rem;
  // padding-top: 8rem;
}
.text-ssm{
  font-size: 1rem;
  color: $main-headings;
  font-weight: 700;
  margin-bottom: 0px;
}
.main-heading {
  color: $white;
  border-left: 3px solid #59A9F7;
  padding-left: 5px;
}

.word-vline {
  font-size: 16px;
  border-left: 1px solid;
  margin: 0px 10px;
}

.footer-pharagraph {
  color: $sub-title;
  // opacity: 0.35;
}

.footerv-line {
  font-size: 14px;
  margin: 0 5px 0 10px;
}

.footer a {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.9rem;
  letter-spacing: 0.2px;
  color: $sub-title;
}

.mob-footer-header-color {
  font-weight: 600;
}

h6 {
  font-weight: 600;
}

.text-black.fs-14 {
  font-weight: 700;

}

.footer-bg{
    background: #F9FAFD;
}
.africa-bg{
    background: #F1F2E6E6;
}
.footer.IT.HomePage{
  background: #FCF9F6 !important;
}
.footer.IotKey{
  background: #F5F9F8 !important;
}

.footer.TH.HomePage{
  background: #F1F0F4 !important;
}
.footer.ES.HomePage{
  background: #FAF7EF !important;
}
.footer.DE.HomePage{
  background: #FFFDF9 !important;
}
.footer.FR.HomePage{
  background: #F7F8FB !important;
}

.footer.africa.HomePage{
  background: #F1F2E6E6 !important;
}


@media (max-width: 575.98px){
  .footer{
    padding-left: 5px;
    padding-right: 5px;
  }
}

</style>
