import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/sass/main.scss';
import './assets/sass/config/variables.scss';
import 'aos/dist/aos.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'aos/dist/aos.js';
import router from './router/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserSecret} from '@fortawesome/free-solid-svg-icons';
import {faStar} from '@fortawesome/free-regular-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import {faArrowLeftLong,faArrowRightLong,faCircle,faCaretDown,faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faSquareTwitter,faSquareFacebook,faSquareInstagram,faLinkedin,faSquareYoutube,faSquareWhatsapp} from '@fortawesome/free-brands-svg-icons'
library.add(faUserSecret,faSquareTwitter,faSquareFacebook,faSquareInstagram,faLinkedin,faSquareYoutube,faSquareWhatsapp,faArrowLeftLong, faArrowRightLong, faCircle,faCaretDown,faStar,faMagnifyingGlass);
import Popper from "vue3-popper";
import AOS from 'aos';
import { createI18n } from 'vue-i18n';
import messages from './lang';



export const i18n = new createI18n({
   globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    legacy: false,
    warnHtmlMessage: false,
    messages
  })
  

createApp(App)
.use(router)
.use(i18n)
.use(AOS.init())
.component('font-awesome-icon', FontAwesomeIcon)
.component("Popper", Popper)
.mount('#app')


  


