<template>
  <div>
    <nav class="navbar hk-navbar navbar-expand-md">
      <div class="container hk-container-padding">
        <router-link :to="'/'" class="navbar-brand">
          <img src="@/assets/logo/hk-logo-black.svg" width="146" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse position-relative"
          id="navbarScroll"
        >
          <ul
            class="navbar-nav ms-md-auto my-2 my-lg-0 navbar-nav-scroll"
            style="--bs-scroll-height: 100px"
          >
            <li class="nav-item">
              <router-link class="nav-link" :to="'/property-management'"
                >Property Management</router-link
              >
            </li>
            <li class="nav-item dropdown has-megamenu">
				<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false"> Product</a>
				<div class="dropdown-menu megamenu container mx-auto" role="menu">
					<div class="row g-3">
						<div class="col-lg-3 col-6">
							<div class="col-megamenu">
								<h6 class="title">Title Menu One</h6>
								<ul class="list-unstyled">
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
								</ul>
							</div>  <!-- col-megamenu.// -->
						</div><!-- end col-3 -->
						<div class="col-lg-3 col-6">
							<div class="col-megamenu">
								<h6 class="title">Title Menu Two</h6>
								<ul class="list-unstyled">
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
								</ul>
							</div>  <!-- col-megamenu.// -->
						</div><!-- end col-3 -->
						<div class="col-lg-3 col-6">
							<div class="col-megamenu">
								<h6 class="title">Title Menu Three</h6>
								<ul class="list-unstyled">
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
								</ul>
							</div>  <!-- col-megamenu.// -->
						</div>    
						<div class="col-lg-3 col-6">
							<div class="col-megamenu">
								<h6 class="title">Title Menu Four</h6>
								<ul class="list-unstyled">
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
									<li><a href="#">Custom Menu</a></li>
								</ul>
							</div>  <!-- col-megamenu.// -->
						</div><!-- end col-3 -->
					</div><!-- end row --> 
				</div> <!-- dropdown-mega-menu.// -->
			    </li>
      <li class="nav-item">
         <router-link class="nav-link" :to="'/company'">About Us</router-link>
      </li>
      <li class="nav-item">
         <router-link class="nav-link" :to="'/services'">Services</router-link>
      </li>
      <li class="nav-item">
         <router-link class="nav-link" :to="'/contact-team'">Contact Us</router-link>
      </li>
      <li class="nav-item" style="border: 1px solid #229cd3;border-radius: 10px;padding: 0px 15px; color: #229cd3;">
         <router-link class="nav-link" :to="'/support'" style=" color: #229cd3;">Support</router-link>
      </li>
      <!-- <li class="nav-item">
         <router-link class="nav-link" :to="'/careers'">C</router-link>
      </li> -->

            <!-- <li class="nav-item"></li>
            <li class="nav-item"></li>
            <li class="nav-item d-flex justify-content-end hk-align-items-center">
              <img
                src="@/assets/logo/bar_img.svg"
                class="menu-btn bar_menu-icon hk-mouse-pointer"
                width="50"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              />
            </li> -->
          </ul>
        </div>
      </div>
    </nav>

    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <ul class="list-group list-group-flush text-center">
          <li class="list-group-item border-bottom-0">
            <router-link class="nav-link" :to="'hk-company'">About Us</router-link
            >
          </li>
          <li class="list-group-item border-bottom-0">
            <router-link class="nav-link" :to="'hk-services'"
              >Services</router-link
            >
          </li>
          <li class="list-group-item border-bottom-0">
            <router-link class="nav-link" :to="'/contact-team'"
              >Contact Us</router-link
            >
          </li>
          <li class="list-group-item border-bottom-0">
            <router-link class="nav-link" :to="'contact-supports'"
              >Support</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  
  </div>
  
</template>
<script>
// import $ from 'jquery'
export default {
  name: "NavBar",
  beforeMount(){
   
  },
  methods:{
   
  }
  
};

</script>
<style lang="scss" scoped>
// @import "@/assets/sass/config/variables.scss";
// @import "@/assets/sass/main.scss";
.router-link-active.router-link-exact-active.nav-link {
  color: #000;
}
.nav-link{
  color:#000;
}
.bar_menu-icon {
  box-shadow: 0px 20px 40px rgb(0 0 0 / 11%);
}
.hk-navbar .navbar-nav .nav-item {
  padding: 0px 15px;

}

.hk-navbar {
  padding: 1.5rem 0;
  position: relative;
  z-index: 111;
  
  .navbar-nav .nav-item:last-child {
    padding: 0px 0px;
  }
  .menu-btn {
    position: fixed;
    z-index: 111;
  }
  

}

.navbar .megamenu {
    padding: 1rem;
}


	.navbar .has-megamenu{
    position:static!important;
  }
	.navbar .megamenu{
    left:0; 
    right:0; 
    width:100%; 
    margin-top:0;  
  }
  .navbar-nav .dropdown-menu{
    margin: auto;
  }
	// .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
	// 	overflow-y: auto;
	//     max-height: 90vh;
	//     margin-top:10px;
	// }


</style>