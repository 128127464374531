<template>
    <div class="lgnswitch">
        <!-- <select class="form-select selectpicker" v-model="$i18n.locale" @change="changeLanguage">
            <option value="en" class="en">English</option>
            <option value="africa">Africa</option>
        </select> -->
        <!-- <select class="form-select selectpicker" v-model="$i18n.locale" @change="changeLanguage">
        <option v-for="option in options" :key="option.value" :value="option.value">
          <img :src="require(`@/assets/contact/support/${option.image}`)" :alt="option.text" class="option-image">
          {{ $t(option.text) }}
        </option>
      </select> -->
    <select class="selectpicker form-select" aria-label="country" name="countries" v-model="$i18n.locale" @change="changeLanguage">
        <option value="en"><span class="emoji">🇺🇸</span> English</option>
        <option value="africa"><span class="emoji">🇿🇦</span> Africa</option>
        <option value="spanish"><span class="emoji">🇪🇸</span> Spanish</option>
        <option value="italian"><span class="emoji">🇮🇹</span> Italian</option>
        <option value="french"><span class="emoji">🇲🇫</span> French</option>
        <option value="german"><span class="emoji"> 🇩🇪</span> German</option>
        <option value="thai"><span class="emoji">🇹🇭</span> Thai</option>
    </select>


        <!-- <div class="dropdown">
            <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown">
                Dropdown button
            </button>
            <ul class="dropdown-menu">
                <li>
                    <div class="dropdown-item" :v-model="$i18n.locale"  >
                           <input v-model="$i18n.locale" id="en" type="radio" class="form-check-input pos"
                                name="english" value="en" @change="changeLanguage">
                            <label class="form-check-label" for="en">
                                English
                            </label>
                    </div>
                </li> 
                 <li>
                    <div class="dropdown-item" >
                            <input v-model="$i18n.locale" id="Africa" type="radio" class="form-check-input pos"
                                name="Africa" value="africa" @change="changeLanguage">
                            <label class="form-check-label" for="Africa">
                                Africa
                            </label>
                    </div>
                </li>
            </ul>
        </div> -->




    </div>
</template>

<script>

// import $ from 'jquery';
export default {
    name: "Language",
    data() {
        return {
            checkLang: localStorage.getItem('language'),
            defultLang: 'en',
            options: [
                { value: 'en', name: 'English', image: 'australia.svg' },
                { value: 'africa', name: 'Africa', image: 'india-grid.svg' },
            ]

        }
    },
    computed: {

    },
    mounted() {
        if (localStorage.getItem('language') == null) {
            this.checkLang = this.defultLang
            this.$i18n.locale = this.checkLang
        }
        if (this.checkLang !== this.$i18n.locale) {
            this.$i18n.locale = this.checkLang
        }

    },
    methods: {
        changeLanguage(obj) {
            localStorage.setItem('language', obj.target.value)
            // console.log(obj.target.value)
            this.scrollBehavior()
        },
        scrollBehavior() {
        document.getElementById('app').classList.add("my-bg-class");
        setTimeout(() => {
            document.getElementById('app').scrollIntoView();
            document.getElementById('app').classList.remove("my-bg-class");
        }, 2000);

    }


    }

}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/config/variables.scss";
@import "@/assets/sass/main.scss";
</style>