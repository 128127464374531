<template>
<nav-bar v-if="false"></nav-bar>
<menu-bar ></menu-bar>
<router-view></router-view>
 <footer-bar></footer-bar>
 <footer-mini></footer-mini>
<!-- <div class="main-spiner"> -->
 <div class="spinner-border text-info" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<!-- </div> -->
</template>

<script>
// import NavBar from './components/NavBar.vue'
import FooterBar from './components/FooterBar.vue'
import FooterMini from './components/FooterMini.vue'
import MenuBar from './components/MenuBar.vue'
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: { FooterBar, FooterMini, MenuBar, NavBar},
  setup() {
    return {
    
    };
  },
  
 
  
}
</script>

<style scoped lang="scss">
.text-info{
  display: none;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
