import en from './translations/en.json'
import spanish from './translations/spanish.json'
import french from './translations/french.json'
import africa from './translations/africa.json'
import italian from './translations/italian.json'
import german from './translations/german.json'
import thai from './translations/thai.json'


export default {
    en, spanish, french, africa, italian, german, thai
}




