<template>
  <div class="hk-nav supreme-container" :class="[this.MobileView, this.GetCountry, this.currentRouteName]">
    <nav class="navbar navbar-expand-md navbar-dark hk-navbar" :class="this.IsDarkMode + ' ' + this.MobileView">
      <div class="container ok">
        <router-link :to="'/'" class="navbar-brand">
          <!-- desktop menu -->
          <div class="d-none d-sm-none d-md-block d-lg-block">
            <img v-if="this.IsDarkMode == 'HK-Black' && this.CheckLocation && this.currentRouteName == 'HomePage'"
              src="@/assets/logo/hk-logo-black.svg" width="146" alt="logo" />
            <img v-if="this.IsDarkMode == 'HK-Black' && !this.CheckLocation && this.currentRouteName !== 'HomePage'"
              src="@/assets/logo/hk-logo-black.svg" width="146" alt="logo" />
            <img v-if="this.IsDarkMode == 'HK-White' && !this.CheckLocation && this.currentRouteName !== 'HomePage'"
              src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo black" />

            <img v-if="this.IsDarkMode == 'HK-Black' && this.WhiteLogo && this.currentRouteName == 'HomePage'"
              src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo black" />
            <img v-if="this.IsDarkMode == 'HK-Black' && this.CheckLocation && this.currentRouteName !== 'HomePage'"
              src="@/assets/logo/hk-logo-black.svg" width="146" alt="logo black" />
            <img v-if="this.IsDarkMode == 'HK-White' && this.CheckLocation && this.currentRouteName !== 'HomePage'"
              src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo black" />

            <!-- <img v-if="this.IsDarkMode == 'HK-Black' && this.GetCountry=='IT'  && this.currentRouteName =='HomePage' " src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo" />

              <img v-if="this.IsDarkMode == 'HK-Black' && this.GetCountry =='IT' && this.currentRouteName !=='HomePage'" src="@/assets/logo/hk-logo-black.svg" width="146" alt="logo black" /> 

              <img v-if="this.IsDarkMode == 'HK-Black' && this.GetCountry !=='IT' && this.currentRouteName =='HomePage'" src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo black" />

              <img v-if="this.IsDarkMode == 'HK-Black' && this.GetCountry !=='IT' && this.currentRouteName !=='HomePage'" src="@/assets/logo/hk-logo-black.svg" width="146" alt="logo black" />

              <img v-if="this.IsDarkMode == 'HK-White' && this.GetCountry !=='IT' && this.currentRouteName !=='HomePage'" src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo black" />

              <img v-if="this.IsDarkMode == 'HK-White' && this.GetCountry =='IT' && this.currentRouteName !=='HomePage'" src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo black" /> -->

            <img v-if="this.IsDarkMode == 'HK-Retail'" src="@/assets/logo/retailkey_logo.svg" width="146"
              alt="retailkey" />
               <img v-if="this.IsDarkMode == 'HK-Paymentkey'" src="@/assets/logo/paymentkey_logo_white.svg" alt="payment key"
              width="165" />

            <img v-if="this.IsDarkMode == 'HK-Black hk-entpage'" src="@/assets/logo/paymentkey_logo.svg" alt="payment key"
              width="165" />
              <img v-if="this.IsDarkMode == 'hk-iotkey'" src="@/assets/logo/iotkeylogo_h.svg" alt="iotkey"
              width="165" />
              <img v-if="this.IsDarkMode == 'hk-tenantKey'" src="@/assets/logo/tenantkey_logo.svg" alt="tenantkey"
              width="165" />
            
            <!-- <img v-if="this.GetPage =='italian' && this.currentRouteName =='HomePage' " src="@/assets/logo/hk_white_logo.svg" width="146" alt="logo" /> -->
            <!-- <img v-if="this.IsDarkMode =='HK-Paymentkey'" src="@/assets/logo/paymentkey_logo.svg" alt="payment key" width="165" /> -->


          </div>
          <!-- Mobile menu -->
          <div class="d-block d-sm-block d-md-none d-lg-none">
            <!-- <img src="@/assets/logo/hk-logo-black.svg" width="146" /> -->
            <img v-if="this.IsDarkMode == 'HK-Black'" src="@/assets/logo/hk-logo-black.svg" width="146"
              alt="logo black" />
            <img v-if="this.IsDarkMode == 'HK-White'" src="@/assets/logo/hk-logo-black.svg" width="146"
              alt="logo black" />
            <img v-if="this.IsDarkMode == 'HK-Retail'" src="@/assets/logo/retailkey_logo.svg" width="146"
              alt="retailkey" />

              <img v-if="this.IsDarkMode == 'hk-iotkey'" src="@/assets/logo/iotkeylogo_h.svg" alt="iotkey"
              width="146" />
            <!-- <img v-if="this.IsDarkMode =='HK-Paymentkey'" src="@/assets/logo/paymentkey_logo_white.svg" width="165" alt="payment key" /> -->
            <img v-if="this.IsDarkMode == 'HK-Paymentkey'" src="@/assets/logo/paymentkey_logo.svg" alt="payment key"
              width="165" />

              <img v-if="this.IsDarkMode == 'HK-Black hk-entpage'" src="@/assets/logo/paymentkey_logo.svg" alt="payment key"
              width="165" />

              
              <img v-if="this.IsDarkMode == 'hk-tenantKey'" src="@/assets/logo/tenantkey_logo.svg" alt="tenantkey"
              width="165" />

          </div>

        </router-link>
        <div>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
          aria-expanded="false" aria-label="Toggle navigation">
          <div class="mob-menu-btn">
            <img v-if="!this.MobileMenu" src="@/assets/logo/bar_img.svg" alt="bar image"
              class=" bar_menu-icon hk-mouse-pointer hkwin" width="35" @click="this.OpenMobileMenu()" />
            <img v-if="this.MobileMenu" src="@/assets/logo/close.svg" alt="bar image"
              class=" bar_menu-icon hk-mouse-pointer hkwin" width="35" @click="this.OpenMobileMenu()" />
          </div>
        </button>

        <!-- desktop menu -->
        <div class="collapse navbar-collapse d-none d-md-flex d-lg-flex hk-desktop-menu" id="main_nav">
          <ul class="navbar-nav">
          </ul>
          <ul class="navbar-nav  ms-auto">
            <li class="nav-item">
              <!-- <router-link class="nav-link" :to="'/property-management'">Property Management</router-link> -->
              <router-link class="nav-link" :to="'/company'"> {{ $t('nav_bar.about_us') }}</router-link>
            </li>
            <li class="nav-item dropdown has-megamenu">
              <div class="nav-link" @click="this.OpenMenu">{{ $t('nav_bar.products') }} 
                <font-awesome-icon icon="fa-solid fa-caret-down" /></div>
              <div class="megamenu container mx-auto border-0" role="menu" v-if="this.show" @mouseleave="MouseLeave">
                <div class="row g-3 border-0 bg-megamenu">
                  <div class="col-lg-5 col-12 mt-0 p-0">
                    <div class="col-megamenu mega-left">
                      <img src="@/assets/home/menu-side-img.svg" alt="menu side" class="w-100">
                    </div>
                  </div><!-- end col-3 -->

                  <div class="col-lg-7 col-12 mt-0">
                    <div class="col-megamenu mega-right">
                      <div class="row">
                        <div class="col-12 mega-product-heading">
                          <h1>
                            {{ $t('nav_bar.products') }}
                          </h1>
                          <hr>

                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/property-management-system'">
                            <div>
                              <img src="@/assets/home/menu-icon/pms.svg" alt="pms">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.pms') }}</h2>
                              <p>{{ $t('product_menus.pms_sub_text') }}</p>
                              <!-- <p>A smart system which helps your property <br> work organisinly perfect.</p> -->
                            </div>
                          </router-link>
                        </div>

                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/rate-management-system'">
                            <div>
                              <img src="@/assets/home/menu-icon/rate_management.svg" alt="rate management">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.rms') }}</h2>
                              <p>{{ $t('product_menus.rms_sub_text') }}</p>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/retail-key'">
                            <div>
                              <img src="@/assets/home/menu-icon/retailkey.svg" alt="retailkey">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.retail_key') }}</h2>
                              <p>{{ $t('product_menus.retail_key_sub_text') }}</p>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/event-key'">
                            <div>
                              <img src="@/assets/home/menu-icon/eventkey.svg" alt="eventkey">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.event_key') }}</h2>
                              <p>{{ $t('product_menus.event_key_sub_text') }}</p>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/central-reservation-system'">
                            <div>
                              <img src="@/assets/home/menu-icon/crs.svg" alt="crs">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.crs') }}</h2>
                              <p>{{ $t('product_menus.crs_sub_text') }}</p>
                            </div>
                          </router-link>

                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/business-intelligence'">
                            <div>
                              <img src="@/assets/home/menu-icon/bi.svg" alt="bi">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.bi') }}</h2>
                              <p>{{ $t('product_menus.bi_sub_text') }}</p>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/payment-key'">
                            <div>
                              <img src="@/assets/home/menu-icon/paymentkey.svg" alt="paymentkey">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.payment_key') }}</h2>
                              <p>{{ $t('product_menus.payment_key_sub_text') }}</p>
                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/integrations'">
                            <div>
                              <img src="@/assets/home/menu-icon/integrations.svg" alt="integrations">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.integrations') }}</h2>
                              <p>{{ $t('product_menus.integrations_sub_text') }}</p>

                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/iotkey'">
                            <div>
                              <img src="@/assets/home/menu-icon/iotkey.svg" alt="iotkey">
                            </div>
                            <div>
                              <h2> {{ $t('product_menus.iot_key') }}</h2>
                              <p>{{ $t('product_menus.iot_key_sub_text') }}</p>

                            </div>
                          </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                          <router-link class="nav-link hk-row-menus" :to="'/tenant-key'">
                            <div>
                              <img src="@/assets/home/menu-icon/tenantkey.svg" alt="tenantkey">
                            </div>
                            <div>
                              <h2>{{$t('product_menus.tetant_key')}}</h2>
                              <p>{{$t('product_menus.tetant_key_sub_text')}}</p>
                            </div>
                          </router-link>
                        </div>

                      </div>
                    </div> <!-- col-megamenu.// -->
                  </div>

                </div><!-- end row -->
              </div> <!-- dropdown-mega-menu.// -->
            </li>
            <li class="nav-item" style="    padding: 0px 40px 0px 0px;">
              <language></language>

            </li>
            <li class="nav-item"></li>
            <li class="nav-item justify-content-end hk-align-items-center d-none d-md-flex d-lg-flex">
              <div v-if="!this.SideMenuBar" class="menu-btn">

                <!-- <img v-if="this.IsDarkMode === 'HK-Black' && 'HK-Retail'"  src="@/assets/logo/bar_img.svg" alt="bar image"
                  class=" bar_menu-icon hk-mouse-pointer hkwin" width="50" @click="this.OpenSideMenu()" /> -->

                <img v-if="this.IsDarkMode === 'HK-Retail'" src="@/assets/logo/bar_img.svg" alt="bar image"
                  class="bar_menu-icon hk-mouse-pointer hkwin" width="50" @click="this.OpenSideMenu()" />

                <img v-if="this.IsDarkMode === 'HK-White'" src="@/assets/logo/bar_white_img.svg" alt="log white"
                  class="bar_menu-icon hk-mouse-pointer" width="50" @click="this.OpenSideMenu()" />

                <img v-if="this.IsDarkMode === 'HK-Black' && this.WhiteLogo && this.currentRouteName == 'HomePage'"
                  src="@/assets/logo/bar_white_img.svg" alt="log white" class="bar_menu-icon hk-mouse-pointer" width="50"
                  @click="this.OpenSideMenu()" />

                <img v-if="this.IsDarkMode === 'HK-Black' && this.CheckLocation && this.currentRouteName == 'HomePage'"
                  src="@/assets/logo/bar_img.svg" alt="log white" class="bar_menu-icon hk-mouse-pointer" width="50"
                  @click="this.OpenSideMenu()" />

                  <img v-if="this.IsDarkMode === 'hk-tenantKey'" src="@/assets/logo/bar_img.svg" alt="bar image"
                  class="bar_menu-icon hk-mouse-pointer hkwin" width="50" @click="this.OpenSideMenu()" />

                <img
                  v-if="this.IsDarkMode === 'HK-Black' && this.GetCountry == 'IT' && this.currentRouteName !== 'HomePage'"
                  src="@/assets/logo/bar_img.svg" alt="log white" class="bar_menu-icon hk-mouse-pointer" width="50"
                  @click="this.OpenSideMenu()" />
                <img
                  v-if="this.IsDarkMode === 'HK-Black' && this.GetCountry !== 'IT' && this.currentRouteName !== 'HomePage'"
                  src="@/assets/logo/bar_img.svg" alt="log white" class="bar_menu-icon hk-mouse-pointer" width="50"
                  @click="this.OpenSideMenu()" />

                <img v-if="this.IsDarkMode === 'HK-Paymentkey'" src="@/assets/logo/bar_white_img.svg" alt="white"
                  class="bar_menu-icon hk-mouse-pointer" width="50" @click="this.OpenSideMenu()" />

                   <img v-if="this.IsDarkMode === 'HK-Black hk-entpage'" src="@/assets/logo/bar_img.svg" alt="black"
                  class="bar_menu-icon hk-mouse-pointer" width="50" @click="this.OpenSideMenu()" />

                  
                  <img v-if="this.IsDarkMode === 'hk-iotkey'" src="@/assets/logo/bar_img.svg" alt="log white" class="bar_menu-icon hk-mouse-pointer" width="50"
                  @click="this.OpenSideMenu()" />
              </div>
              <div v-if="this.SideMenuBar" class="menu-btn bar_menu-icon hk-mouse-pointer" width="50"
                @click="this.OpenSideMenu()">
                <img src="@/assets/logo/close.svg" alt="close" class="bar_menu-icon hk-mouse-pointer" width="50" />
              </div>
            </li>

          </ul>
        </div>
        <!-- mobile menu -->
        <div class="collapse navbar-collapse d-flex d-md-none d-lg-none hk-mobile-menu pt-2 " id="main_nav"
          v-if="MobileMenu">
          <ul class="navbar-nav mob-nav-overflow">
            <li class="nav-item">
              <language></language>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="'/company'">{{ $t('nav_bar.about_us') }}</router-link>
            </li>
            <li class="nav-item">
              <div class="dropdown">
                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ $t('nav_bar.products') }}
                </a>
                <ul class="dropdown-menu border-0" aria-labelledby="dropdownMenuLink">
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/property-management-system'">{{ $t('product_menus.pms') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/rate-management-system'">{{ $t('product_menus.rms') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/retail-key'">{{ $t('product_menus.retail_key') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/event-key'"> {{ $t('product_menus.event_key') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/central-reservation-system'">{{ $t('product_menus.crs') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/business-intelligence'">{{ $t('product_menus.bi') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item" :to="'/payment-key'">{{ $t('product_menus.payment_key') }}</router-link>
                  <router-link class="nav-link hk-row-menus dropdown-item"
                    :to="'/integrations'">{{ $t('product_menus.integrations') }}</router-link>
                    <router-link class="nav-link hk-row-menus" :to="'/iotkey'"><h2>{{ $t('product_menus.iot_key') }}</h2></router-link>
                    <router-link class="nav-link hk-row-menus" :to="'/tenant-key'"><h2>{{$t('product_menus.tetant_key')}}</h2></router-link>
                </ul>
              </div>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" :to="'/services'">{{ $t('nav_bar.services') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="'/contact-team'"> {{ $t('nav_bar.contact_us') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="'/support'">{{ $t('nav_bar.support') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="'/news-blogs/news'">{{ $t('nav_bar.news_blogs') }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="'/careers'"> {{ $t('nav_bar.join_our_team') }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>


    <!-- side menu -->
    <div v-if="this.SideMenuBar" class="side-menu-grid" :class="{ sidenavopen: SideMenuBar }">
      <div class="hk-menus">
        <div class="header-close-button">
          <button class="btn btn-sm " @click="this.OpenSideMenu()">
            <span>X</span>
          </button>
        </div>
        <div class="sidemenu-height">
          <ul class="list-group list-group-flush text-center">
            <!-- <li class="list-group-item border-bottom-0">
            <router-link class="nav-link" :to="'/company'">About Us</router-link>
          </li> -->
            <li class="list-group-item border-bottom-0">
              <router-link class="nav-link" :to="'/services'">{{ $t('nav_bar.services') }}</router-link>
            </li>
            <li class="list-group-item border-bottom-0">
              <router-link class="nav-link" :to="'/contact-team'">{{ $t('nav_bar.contact_us') }}</router-link>
            </li>
            <li class="list-group-item border-bottom-0">
              <router-link class="nav-link" :to="'/support'">{{ $t('nav_bar.support') }}</router-link>
            </li>
            <li class="list-group-item border-bottom-0">
              <router-link class="nav-link" :to="'/news-blogs/news'">{{ $t('nav_bar.news_blogs') }}</router-link>
            </li>
            <li class="list-group-item border-bottom-0">
              <router-link class="nav-link" :to="'/careers'">{{ $t('nav_bar.join_our_team') }}</router-link>
            </li>
          </ul>
          <div class=" d-block mx-auto text-center">
            <ul class="list-inline mb-0 hk-list-inline-item ">
              <li class="list-inline-item fs-14 text-uppercase">
                {{ $t('footer.contact_with_us') }} <span> - </span>
              </li>
              <li class="list-inline-item hk-list-inline-item">
                <a href="https://www.facebook.com/hotelkeyapp/" class="hk-a" target="_blank">
                  <font-awesome-icon icon="fa-brands fa-square-facebook" class="" />

                </a>
              </li>
              <li class="list-inline-item hk-list-inline-item">
                <a href="https://twitter.com/HotelKeyInc" class="hk-a" target="_blank">
                  <font-awesome-icon icon="fa-brands fa-square-twitter" class="" />
                </a>
              </li>
              <li class="list-inline-item hk-list-inline-item">
                <a href="https://www.instagram.com/hotelkeyinc/" class="hk-a" target="_blank">
                  <font-awesome-icon icon="fa-brands fa-square-instagram" class="" />
                </a>
              </li>
              <li class="list-inline-item hk-list-inline-item">
                <a href="https://www.linkedin.com/company/hotelkey/?viewAsMember=true" class="hk-a" target="_blank">
                  <font-awesome-icon icon="fa-brands fa-linkedin" class="" />
                </a>
              </li>
              <li class="list-inline-item hk-list-inline-item">
                <a href=" https://wa.me/19724397067" aria-label="whatsapp" class="hk-a" target="_blank">
                  <font-awesome-icon icon="fa-brands fa-square-whatsapp" class="" />
                </a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import Language from './Language.vue';
import axios from 'axios'

// import { computed } from "vue";
export default {
  components: { Language },
  name: "MenuBar",
  computed: {
    currentRouteName() {
      this.OnClickClose();
      return this.$route.name;
    },
    IsDarkMode() {
      // console.log(this.$route.meta.darkMode)
      return this.$route.meta.darkMode;

      // var isDarkMode = this.$route.meta.darkMode;
      // if(isDarkMode == null)
      // {
      //   isDarkMode = false;
      // }
      // return  isDarkMode;
    },
    MobileView() {
      return this.$route.meta.mobileMode
    },

    IsAfrica() {
      return this.$route.meta.mobileMode;
    }
  },

  data() {
    return {
      show: false,
      SideMenuBar: false,
      MobileMenu: false,
      GetCountry: '',
      CheckLocation: null,
      WhiteLogo: null
    };


  },
  updated() {

  },
  mounted() {
    // document.getElementById('app').classList.add("my-bg-class");

    $(window).scroll(function () {
      $('nav').toggleClass('scrolled', $(this).scrollTop() > 50);
    });
    this.getUserLocation()
  },

  methods: {
    OpenMenu() {
      this.show = !this.show;
      this.SideMenuBar = false;
      this.MobileMenu = false;

    },
    OpenSideMenu() {
      this.SideMenuBar = !this.SideMenuBar
      this.show = false;
      this.MobileMenu = false;
    },
    OpenMobileMenu() {
      this.MobileMenu = !this.MobileMenu
      this.show = false;
    },
    // myOpenSideMenu(){
    //   this.SideMenuBar = !this.SideMenuBar
    //   this.show = false;
    // },
    MouseLeave() {
      this.show = false;
    },
    OnClickClose() {
      this.show = false;
    },

    getUserLocation() {
      const apiUrl = 'https://ipapi.co/json/';
      axios.get(apiUrl)
        .then(response => {
          this.GetCountry = response.data.country_code;
          if (this.GetCountry == 'IT' || this.GetCountry == 'TH') {
            this.CheckLocation = false;
            this.WhiteLogo = true;
          }
          else {
            this.CheckLocation = true;
            this.WhiteLogo = false;
          }
        })
        .catch(error => { console.log('Error:', error) })
        .finally(() => (
          setInterval(() => {
            //   document.getElementById('app').scrollIntoView(); 
            //  document.getElementById('app').classList.remove("my-bg-class");
          }, 3000)
        ))
    },



    // getUserLocation() {
    //   document.getElementById('app').classList.add("my-bg-class");
    //   const apiUrl = 'https://ipapi.co/json/';
    //   axios.get(apiUrl)
    //     .then(response => {
    //       this.GetCountry = response.data.country_code;
    //       console.log(this.GetCountry);
    //       if(this.GetCountry == 'IT'){
    //         this.CheckLocation = false;
    //         this.WhiteLogo = true;
    //         console.log(this.CheckLocation)
    //         setTimeout(() => {
    //         document.getElementById('app').scrollIntoView();
    //         document.getElementById('app').classList.remove("my-bg-class");
    //     }, 800);

    //       }
    //       else{
    //         this.CheckLocation = true;
    //         this.WhiteLogo = false;
    //         setTimeout(() => {
    //         document.getElementById('app').scrollIntoView();
    //         document.getElementById('app').classList.remove("my-bg-class");
    //     }, 800);
    //         console.log(this.CheckLocation)
    //       }
    //     })
    //     .catch(error => {
    //       console.log('Error:', error);
    //     });
    // }

  },
  watch: {
    $route(to, from) {
      this.show = false;
      this.SideMenuBar = false;
      this.MobileMenu = false;
      this.getUserLocation();
    }
  }


};

</script>
<style lang="scss" scoped>
@import "@/assets/sass/config/variables.scss";
@import "@/assets/sass/main.scss";


.mega-right .nav-link:hover {

  h2,
  p {
    color: $hk-blue;
  }
}

.mega-right .router-link-active.router-link-exact-active.nav-link {

  h2,
  p {
    color: $hk-blue;
  }
}

// .HK-Black .mega-right .router-link-active.router-link-exact-active.nav-link {
//   color: $hk-blue;
// }
// .HK-Retailkey .mega-right .router-link-active.router-link-exact-active.nav-link {
//   color: $hk-blue;
// }

.offcanvas-body {
  display: flex;
  flex-direction: column;

  .list-group-flush {
    flex-grow: 1;
  }

  .nav-link {
    font-size: 2rem;
    padding-bottom: 3rem;
  }
}

.HK-White {
  .nav-link {
    color: $white;
  }
}

.HK-Paymentkey {
  .nav-link {
    color: $white;
    
  }
 

}

.HK-Black {
  .nav-link {
    color: $main-headings;
  }
}

.hk-tenantKey {
  .nav-link {
    color: $main-headings;
  }
}

.IT.HomePage .HK-Black .nav-link {
  color: $white !important;
}

.TH.HomePage .HK-Black .nav-link {
  color: $white !important;
}

// .ES.HomePage .HK-Black .nav-link {
//   color: $white !important;
// }


.HK-White {
  .mega-right {
    .nav-link {
      color: $main-headings;
    }

  }
}

.HK-Paymentkey {
  .mega-right {
    .nav-link {
      color: $main-headings;
    }
  

  }
}


.mega-product-heading {
  h1 {
    font-size: 1.25rem;
    line-height: 35px;
    font-weight: 700;
  }
}

.hk-row-menus {
  display: flex;

  img {
    width: 30px;
    margin-right: 15px;
  }

  h2 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 5px;

  }

  p {
    color: $main-headings;
    font-size: 14px;
  }
}



.nav-link:focus,
.nav-link:hover {
  color: #000;
}

.navbar-nav.hk-desktop-menu {
  align-items: center;
}

.navbar-nav.hk-mobile-menu {
  align-items: left;

  .dropdown-toggle {
    color: $main-headings
  }

  .show>.nav-link {
    color: $main-headings !important;
  }
}

.bar_menu-icon {
  box-shadow: 0px 20px 40px rgb(0 0 0 / 11%);
}

.hk-navbar .navbar-nav .nav-item {
  padding: 0px 15px;

}

.hk-navbar {

  position: relative;
  top: 0px;
  width: 100%;
  // overflow-x: hidden;

  .navbar-nav .nav-item:last-child {
    padding: 0px 0px 0px 0px;
  }

  .menu-btn {
    position: fixed;
    z-index: 111;
  }


}

.bg-megamenu {
  background: $white;
  box-shadow: 0px 20px 50px #00000012;

  // padding: 0.75rem;
  border-radius: 15px;

  .col-megamenu {
    padding: 1.2rem;

    .nav.nav-tabs {
      float: left;
      display: block;
      margin-right: 20px;
      border-bottom: 0;
      border-right: 1px solid #ddd;
      padding-right: 15px;



    }

    .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      background: #ccc;
    }

    .nav-tabs .nav-link.active {
      color: #495057;
      background-color: #007bff !important;
      border-color: transparent !important;
    }

    .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 0rem !important;
      border-top-right-radius: 0rem !important;
    }

    .tab-content>.active {
      display: block;
      background: $bg-secondary;
      min-height: 165px;
    }

    .nav.nav-tabs {
      float: left;
      display: block;
      margin-right: 20px;
      border-bottom: 0;
      border-right: 1px solid transparent;
      padding-right: 15px;
    }
  }

}



.navbar .has-megamenu {
  position: static !important;
}

.navbar .megamenu {
  left: 0;
  right: 0;
  width: 100%;
  margin-top: 1.75rem;
  position: absolute;
  padding: 1rem;
  z-index: 111;
}

.navbar-nav .dropdown-menu {
  margin: auto;
}

// .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
// 	overflow-y: auto;
//     max-height: 90vh;
//     margin-top:10px;
// }
.nav-item {
  cursor: pointer;
}

.mega-left {
  img {
    border-radius: 20px;
  }

}

.mega-right {
  .nav-pills .nav-link {
    text-align: left;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
}

.product-main-menu {
  padding-top: 1rem;
  background: $white;

}

.product-main-submenu {
  background: $bg-secondary;
  padding-top: 1rem;
  flex: 2;
  min-height: 550px;

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background: $bg-secondary;
    color: $main-headings;
  }

  .nav-link {
    padding: 1rem 0.5rem 1rem 0.5rem;
  }


}

.hk-nav {
  position: relative;
  z-index: 1111;
}

.side-menu-grid {
  // position: absolute;
  //   width: 100%;
  //   height: 100vh;
  //   background: #00000000;
  //   top: 0rem;
  //   -webkit-transition: all 0.5s ease;
  //   transition: all 0.5s ease;
  position: absolute;
  // width: 100%;
  // height: 100vh;
  // background: rgba(0, 0, 0, 0);
  top: 0rem;
  transition: all 0.5s ease;
  z-index: 11;

  .hk-menus {
    position: fixed;
    width: 400px;
    height: 100vh;
    right: 0;
    top: 0rem;
    z-index: 1111;
    background: $white;

    .nav-link {
      font-size: 2rem;
      font-weight: 500;
      padding-bottom: 3rem;
    }

    .header-close-button {
      padding: 1.32rem 1rem 3rem 0px;
      display: flex;
      justify-content: flex-end;

      button {
        background: $main-headings;
        color: $white;
        font-size: 1.5rem;
        border-radius: unset;
        width: 50px;
        height: 50px;
        visibility: hidden;
      }
    }
  }

  .list-grou {
    --bs-list-group-bg: #ff090900;
  }
}

.sidenavopen {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.HK-Retail .nav-link {
  color: $main-headings !important;
}

.sidemenu-height {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 10rem;
}

@media (max-width: 575.98px) {



  .hk-navbar {
    padding: 10px 5px;
    position: fixed !important;
    top: 0px;
    width: 100%;
    background: #fff;

    .scrolled {
      background: #fff;
    }
  }

  .hk-nav .navbar-toggler {
    background-color: transparent;
    padding: 0;
  }

  .HK-White .nav-link {
    color: $main-headings !important;
  }

  .HK-Black .nav-link {
    color: $main-headings !important;
  }

  .IT.HomePage .HK-Black .nav-link {
    color: $main-headings !important;
  }

  .TH.HomePage .HK-Black .nav-link {
    color: $main-headings !important;
  }

  .hk-navbar .navbar-nav .nav-item {
    padding: 0px 0px !important;
  }

  .mob-nav-overflow {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 18rem;
    flex: 1;

    .nav-link {
      padding-bottom: 1rem;
      padding-top: 1rem;
      font-weight: 600;
    }

    .dropdown {
      padding-bottom: 1rem;
      padding-top: 1rem;
      font-weight: 600;
    }
  }

  .nav-africa-bg {
    background: transparent !important;
  }

  .africa {
    background: transparent;

    .navbar-toggler {
      border: var(--bs-border-width) solid rgb(255 255 255 / 0%) !important;
    }

    .scrolled {
      background: #fff;
    }
  }
}
</style>